#line {
  stroke-dasharray: 10, 5; /* creates a dashed line */
  stroke-width: 0.2;
  animation: moveDots 1s linear infinite; /* applies the animation */
}

@keyframes moveDots {
  to {
    stroke-dashoffset: -30;
  }
}
